<template>

  <div>

    <!-- Filters -->
    <UsersFilter
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUsersTable"
        class="position-relative"
        :items="users"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Status -->
        <template #cell(isSuspended)="data">
          <b-badge
            pill
            :variant="`light-${resolveSuspensionStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

         <!-- Column: User -->
        <template #cell(photo)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="resolvePhoto(data.item.profile.photo)"
                :text="avatarText(data.item.profile.fullName)"
                :variant="`light-${resolveSuspensionStatusVariant(data.item.status)}`"
                
              />
            </template>
          </b-media>
        </template>

           <!-- Column: Created At -->
        <template #cell(createdAt)="data">
           <div v-if="data.item.createdAt" class="text-nowrap">
            <strong>
              {{formatDate(Date.parse(data.item.createdAt), {month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'})}}
              </strong>
           </div>
        </template>     

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="button"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item-button @click="showSuspensionStatusModal(data.item)" v-if="$can(Action.Update, Subject.USER)">
              <!-- <feather-icon icon="FileTextIcon" /> -->
              <span>Toggle Suspension</span>
            </b-dropdown-item-button>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BDropdownItemButton, BPagination, BTabs, BTab,BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText, formatDate } from '@core/utils/filter'
import UsersFilter from './components/UsersFilter.vue'
import useUsers from './useUsers'
import Ripple from 'vue-ripple-directive'
import { Action, Subject } from '@/libs/acl/config'

export default {
  components: {
    UsersFilter,
    // DriverListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BPagination,
    BTabs, 
    BTab,
    BFormCheckbox,

    vSelect,
  },
  directives: {
    Ripple,
  },
   data() {
    return {
    }
  },
  methods: {
    showSuspensionStatusModal(user) {
      let status = user.isSuspended ? "Activate" : "Suspend";
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to update ${user.profile.fullName}'s suspension status.`, {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'primary',
          okTitle: status,
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value) {
            this.updateSuspensionStatus(user.token)
          }
        })
    },
  },
  setup() {

    onMounted(() => {
      fetchUsers()
    })
    const isAddNewDriverSidebarActive = ref(false)


    const statusOptions = [
      { label: 'Suspended', value: 'inactive' },
      { label: 'Active', value: 'active' },
    ]

    const {
      fetchUsers,
      users,
      updateSuspensionStatus,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUsersTable,
      refetchData,

      // UI
      resolveSuspensionStatusVariant,
      resolvePhoto,

      // Extra Filters
      statusFilter,
    } = useUsers()

    return {

      // Sidebar
      isAddNewDriverSidebarActive,

      fetchUsers,
      users,
      updateSuspensionStatus,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUsersTable,
      refetchData,

      // Filter
      avatarText,
      formatDate,

      // UI
      resolveSuspensionStatusVariant,
      resolvePhoto,


      statusOptions,

      // Extra Filters
      statusFilter,

      // Authorisation
      Action,
      Subject
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
